import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';
class SILVESTERBUFFET extends React.Component {
   
  render() {
    var height = window.innerHeight-( window.innerHeight*0.25);
    return (
        <>
               <p>Aufgrund unseres 20 Jahre Jubiläums haben wir entschieden, ein <b>Silvesterbuffet</b> im Wert von 60 € zu machen. Schmökern Sie in unserer <b>Silvesterbuffetkarte</b>. Bitte genießen Sie ohne Zurückhaltung. Pay as you wish.</p>

        {/* <img src='/menucartimages/Silvesterbuffet_1.jpg' style={{width:"100%"}}/> */}
        <MenuImage imageUrl="/menucartimages/Silvesterbuffet_1.jpg"/>
        </>
        );
  }
}

export class MenuImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {imageUrl : props.imageUrl };
}
   
  render() {
    return (
        <>
          <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' ,lg:'none'} }}>
        <img src={this.state.imageUrl} style={{width:'100%'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' ,lg:'none'} }}>
        <img src={this.state.imageUrl} style={{width:'80%',margin:'auto'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none',lg:'flex' } }}>
        <img src={this.state.imageUrl} style={{width:'50%',margin:'auto'}}/>
        </Box>
        </>
        );
  }
}

export default SILVESTERBUFFET; 

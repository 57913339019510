import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
import { MenuImage } from './SILVESTERBUFFET';
class Kulinarische extends React.Component {
   
  render() {
    var height = window.innerHeight-( window.innerHeight*0.25);
    return (
        <>
        
        {Array.apply(null, {length: 17}).map(Number.call, Number).map(e => e+1).map( e=>{
             var url = '/menucartimages/Speisekarte_09122024_page-000'+e+".jpg";
             if(e>=10){
              url = '/menucartimages/Speisekarte_09122024_page-00'+e+".jpg";
             }
             
             return <MenuImage imageUrl={url}/>
        }) }

        {/* <img src='/menucartimages/Speisekarte_09122024_page-0001.jpg' style={{width:"100%"}}/> */}
       {/* <embed src="Speisekarte deutsch final.pdf#toolbar=0" width="100%" height={height} type="application/pdf">
</embed>		 */}

        </>
        );
  }
}
export default Kulinarische; 
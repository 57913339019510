import React, { useEffect, useState } from 'react';  
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Image, Margin } from '@mui/icons-material';
import AKTUELLES from '../Privacy/AKTUELLES';
import { fetchResponse } from '../../restapiutil/RestAPIClient';
const ColorButton = styled(Button)(({ theme }) => ({
  color: '#d7ae2f',
  backgroundColor: '#112f8a',
  '&:hover': {
    backgroundColor: "#9f2f3c",
  },
}));

class IndexElement extends React.Component
{
  constructor(props) {
    super(props);
   
  }

  
  async  getMittagsmenu() {
    const APPLICATION_JSON = "application/json";
    var dataurl= "reserve/mittagsmenu";
   
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON };
    const response = await fetchResponse(dataurl,"GET",headers,{});
    if(response != null && response.responseData!=null){

       // this.setState({text:decodeURI(response.responseData.data)});
       // console.log(this.state);
    }
}
    componentDidMount(){
       this.getMittagsmenu();
  }
  
  
  render()
  {
    var height = window.innerHeight-( window.innerHeight*0.25);
    //var perf =require('./../../Mittagsmenükarte-converted.html');
    return (
      <><div class="continer-fluid p-3">



        {/* <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' ,lg:'none'} }}>
    <img src='/curryHome.jpeg' style={{width:'100%'}}/>
    </Box>
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' ,lg:'none'} }}>
    <img src='/curryHome.jpeg' style={{width:'80%',margin:'auto'}}/>
    </Box>
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none',lg:'flex' } }}>
    <img src='/curryHome.jpeg' style={{width:'50%',margin:'auto'}}/>
    </Box> */}

        <h6>Herzlich willkommen!</h6>
        {/* <img src='/menucartimages/YearEnd-2024.jpg' style={{width:"70%",marginBottom:"10px"}}/> */}


        {/* <p><b>ACHTUNG: Wir haben auch neue Öffnungszeiten.</b></p>
    <p>
    <b>Dienstag – Freitag</b><br/>
11 – 15 Uhr<br/>
17 – 23 Uhr<br/>
(Bald auch am Montag für Sie geöffnet. Nähere Infos dazu, werden
Sie auf unserer Homepage finden)<br/>
<b>Samstag, Sonntag und Feiertage</b><br/>
9:30 – 23 Uhr (bis 15 Uhr Frühstück & Brunch)


    </p>

  <p>Jeden Samstag und Sonntag (auch an Feiertagen) nehmen wir Sie mit unserem neuen Frühstücks- oder Brunchangebot auf eine Reise durch die weniger
  bekannten Speisen Sri Lankas mit – freuen Sie sich auf eine Zusammenstellung neuer Geschmackserlebnisse!</p>


    <h6>Vorab können Sie unter <a href='/brunch'><b>FRÜHSTÜCK & BRUNCH</b> </a>in unseren Speisekarten schmökern. </h6> */}
        {/* <div>Unter <b>AKTUELLES</b> finden Sie unsere <a href='/Weihnachtsmenu'>Weihnachtsmenü</a>, <a href='/Silvesterbuffet'>Silvesterbuffet </a>
   
    . Wir bieten auch diverse Angebote für Firmenfeiern an. Kontaktieren Sie uns.
    </div>
    <br/>
    <p>Aufgrund unseres 20 Jahre Jubiläums haben wir entschieden, ein <b>Silvesterbuffet</b> im Wert von 60 € zu machen. Schmökern Sie in unserer <b><a href='/Silvesterbuffet'>Silvesterbuffetkarte</a></b>. Bitte genießen Sie ohne Zurückhaltung. Pay as you wish.</p> */}

        {/* <p>Ein weiteres Angebot dürfen wir Ihnen bekannt geben. Wir bieten auch zukünftig ein <b>Mittagsbuffet</b> an. Das
   <b> Mittagsbuffet</b>  (solange der Vorrat reicht) wird von Dienstag bis Freitag zu einem Preis von 18€ angeboten.</p> */}
        {/* <p>Tauchen Sie ein in die srilankische Küche.</p> */}
        <div style={{ margin: "5px" } }>
        <p> Ein weiteres Angebot dürfen wir Ihnen bekannt geben. </p><p>Wir bieten auch zukünftig ein Mittagsbuffet an.
          Das All you can Eat - Mittagsbuffet (solange der Vorrat reicht) wird von Dienstag bis Freitag von 11:30 bis 14:30 zu einem Preis von 15€ angeboten. </p><p>
          Es gibt auch die Möglichkeit, gegen einen Aufpreis von 5 Euro ein All you can Drink daraus zu machen (dieses All You can Drink gilt dann allerdings nur  für einen Zeitraum von max. 1 Stunde), a la carte Getränkebestellungen sind natürlich ebenso möglich.
        </p><p>Bitte unbedingt reservieren!
       
        </p> <p> Nur Barzahlung möglich!
        </p> <p>
          Wir freuen uns auf Ihren Besuch! </p><Link to={"/Mittagsmenu"} style={{ textDecoration: 'none' }}><ColorButton variant="contained" color='secondary'>Jetzt neu! MITTAGSMENÜ</ColorButton></Link>         

        </div>
       

{/* <img src='/Silvesterbuffet.jpg' style={{width:"100%",marginBottom:"20px"}}/>
                 <img src='/Weihnachtsmenukarte.jpg' style={{width:"100%",marginBottom:"20px"}}/>
                 <AKTUELLES showTitle="false"></AKTUELLES> */}
        <div class="jumbotron" style={{ 
          width: "100%",
          // height: "400px",
          marginBottom:"20px",
          "background-size": "100% 100%",
        //  "background-position": "center",
     // backgroundImage: `url("./background.jpeg")` 
    }}>
      
		            <h2 class="jumbotron-description-container">
                <span class="jumbotron-description-left-dash ml-auto"></span>
                
                 <span class="jumbotron-description lead">Spezialitäten aus Sri Lanka</span>
                <span class="jumbotron-description-right-dash mr-auto"></span>
            </h2>
            <h1 class="lead">Curryinsel</h1>
            {/* <p style={{"font-size": "1.25rem"}}>Derzeit nur Barzahlung möglich</p>
            <p style={{"font-size": "1.25rem"}}>Cash only</p> */}
            <Link to={"/reservierung"} style={{ textDecoration: 'none' }}><ColorButton variant="contained" color='secondary'>RESERVIERUNG</ColorButton></Link>         
            </div>         
            
              
              
             


        
    </div></>
    )
  }
}
export default IndexElement;
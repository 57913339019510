import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class Brunch extends React.Component {
   

  render() {
    var height = window.innerHeight-( window.innerHeight*0.15);
    return (
        <>
       <header class="entry-header">
				<h1 class="entry-title page-title" >  Brunch</h1>	</header>
        {/* <h4 class="   rt-heading" >Frühstück &  Nähere Informationen erhalten Sie in Kürze hier!</h4> */}
       
       <h4> Samstag, Sonntag und Feiertag </h4>
       <h4>All You can Eat  </h4>
       <h4> von 9:30 bis 15 Uhr</h4>
       <h4>25,50€/Person</h4>
       <br></br>
        <h5>Wir nehmen Sie mit auf eine Reise durch die weniger bekannten Speisen Sri Lankas - freuen Sie sich auf eine Zusammenstellung neuer Geschmackserlebnisse! 

        </h5>
        <br></br>
        <h5>Wir ersuchen um Reservierung!</h5>
        {/* <img src='/Fruhstucks1.png' style={{width:"100%",marginBottom:"20px"}}/>
        <img src='/Fruhstucks2.png' style={{width:"100%",marginBottom:"20px"}}/> */}
        <img src='/Fruhstucks3.png' style={{width:"100%",marginBottom:"20px"}}/>
        <img src='/Fruhstucks4.png' style={{width:"100%",marginBottom:"20px"}}/>
        {/* <embed src="Frühstücks-Brunchkarte_Curry Insel.pdf#toolbar=0" width="100%" height={height} type="application/pdf">
</embed>	 */}
        
       
        </>
        );
  }
}
export default Brunch; 
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

class Footer extends React.Component {


  render() {
    return (
      <div class="jumbotron text-center">
        <div>
          <p class="copy"> 
          <div class='row'>
            <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>Lenaugasse 4 | 1080 Vienna &nbsp;&nbsp;&nbsp;&nbsp;</div>
            
              {/* <span style={{"width": "120px"}}>Öffnungszeiten:</span>
              <span  style={{"text-align": "left","width": "200px"}}>&nbsp;Di - Fr 17.00 bis 23.00; Sa, So 11.00 bis 23.00 </span>
               */}
              <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'><span style={{"width": "120px"}}>  <Link to='/OpeningTime' style={{ textDecoration: 'underline', color: 'white' }}>Öffnungszeiten</Link></span></div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
            ©2025curryinsel
            {/* <a href='/impressum' > Impressum</a> */}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to='/impressum' style={{ textDecoration: 'underline', color: 'white' }}>Impressum</Link>
            </div>
          </div>
        </p>

        </div>
      </div>
    )
  }
}
export default Footer;
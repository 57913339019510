import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
import { fetchResponse } from '../../restapiutil/RestAPIClient';
class AKTUELLES extends React.Component {
  constructor(props) {
    super(props);
    this.state = {text:"",isTitleRequired : props.showTitle };
    
   

}

  async componentDidMount (){
    this.getMittagsmenu();
  
}

  async  getMittagsmenu() {
    const APPLICATION_JSON = "application/json";
    var dataurl= "reserve/mittagsmenu";
   
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON };
    const response = await fetchResponse(dataurl,"GET",headers,{});
    if(response != null && response.responseData!=null){

        this.setState({text:decodeURI(response.responseData.data)});
        console.log(this.state);
    }
}
   
  render() {
    var height = window.innerHeight-( window.innerHeight*0.25);
    console.log(this.state.isTitleRequired);
    return (
        <>
        {/* <p>Wir bieten ab jetzt auch ein Mittagsbuffet für Sie an.<br/>
        Dies wir täglich aktualisiert.</p> */}
        {/* { this.state.isTitleRequired=="true" ?<p>Ein weiteres Angebot dürfen wir Ihnen bekannt geben. Wir bieten auch zukünftig ein <b>Mittagsbuffet</b> an. Das
         <b> Mittagsbuffet</b>  (solange der Vorrat reicht) wird von Dienstag bis Freitag zu einem Preis von 18€ angeboten.</p>
 : null } */}
            
        
         
            <div style={{ margin: "5px" } }>
       
      <p> Ein weiteres Angebot dürfen wir Ihnen bekannt geben. </p> 

<p>Wir bieten auch zukünftig ein Mittagsbuffet an. 
Das All you can Eat - Mittagsbuffet (solange der Vorrat reicht) wird von Dienstag bis Freitag von 11:30 bis 14:30 zu einem Preis von 15€ angeboten. </p> 
<p>
Es gibt auch die Möglichkeit, gegen einen Aufpreis von 5 Euro ein All you can Drink daraus zu machen (dieses All You can Drink gilt dann allerdings nur  für einen Zeitraum von max. 1 Stunde), a la carte Getränkebestellungen sind natürlich ebenso möglich. 
</p> <p>
Bitte unbedingt reservieren!</p> <p>
Nur Barzahlung möglich!</p> <p>
Wir freuen uns auf Ihren Besuch! </p> </div>

<div style={{"text-align":"left",paddingLeft:"30px"}} className='mittagsmenu' dangerouslySetInnerHTML={{ __html: this.state.text }} /> 

        </>
        );
  }
}
export default AKTUELLES; 
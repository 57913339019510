import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fetchResponse, fetchResponseWithBody,SHOPREFID } from '../../restapiutil/RestAPIClient';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { deleteOrder } from '../Reservierung/RestService';
import { Editor } from '@tinymce/tinymce-react';
class MittagsBuffetAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {list:[],currentItem:"upcoming",text:null,textData:""};
       

    }

   
     log = () => {
      if (this.state.text.getContent()) {
        console.log(this.state.text.getContent());
        console.log(encodeURI(this.state.text.getContent()));
        this.saveContent(encodeURI(this.state.text.getContent()));
      }
    };

    async componentDidMount (){
         this.getOrders();
       
    }

    async  getOrders() {
        const APPLICATION_JSON = "application/json";
        var dataurl= "reserve/mittagsmenu";
       
        const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON };
        const response = await fetchResponse(dataurl,"GET",headers,{});
        if(response != null && response.responseData!=null){

            this.setState({textData:decodeURI(response.responseData.data)});
            console.log(this.state);
        }
    }

    async saveContent(data){
        const dataurl = "reserve/mittagsmenu";
        const APPLICATION_JSON = "application/json";
        const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
        const response = await fetchResponseWithBody(dataurl,"POST",headers,{},{data:data});
        if(response != null && response.responseData!=null){
          alert("Saved succesfully");
      }else{
        alert("Error Occured Please try again");
      }
        this.getOrders();
    }

   
  
     


    render() {
       
        return (
            <div class="continer-fluid p-3">
                <div class='row'>
                    <div class="col-md-12">
                    <FormControl>
      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
      <Editor
        apiKey='bcsaq50fopl6mn2dlldgdf5ehwottaeujt32j2d0x8hhnpbl'
        onInit={(_evt, editor) => this.state.text = editor}
        initialValue= {this.state.textData}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      <div style={{margin:"10px"}}></div>
            <button onClick={this.log} style={{padding:"5px",maxWidth:"300px",margin:"auto"}}>Save the Content</button>

    </FormControl>
                    
                        
                    </div>
                </div>
            </div>

        );
    };
}

export default MittagsBuffetAdmin;
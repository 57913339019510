import React, { Component } from 'react';
import { BrowserRouter, Routes , Route,Link } from 'react-router-dom' 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header'; //Include Heder
import Footer from './Footer'; //Include Footer
import Reservierung from './components/Reservierung/Reservierung'; 
import Uberuns from './components/Uberuns/Uberuns'; 
import Privacy from './components/Privacy/Privacy'; 
import IndexElement from './components/IndexElement/IndexElement'; 
import AdminOrderView from './components/Admin/AdminOrderView';
import Impressum from './components/Privacy/Impressum';
import Contact from './components/Privacy/contact';
import Brunch from './components/Privacy/brunch';
import Catering from './components/IndexElement/Catering';
import Kulinarische from './components/Privacy/Kulinarische';
import AKTUELLES from './components/Privacy/AKTUELLES';
import Weihnachtsmenukarte from './components/Privacy/Weihnachtsmenukarte';
import SILVESTERBUFFET from './components/Privacy/SILVESTERBUFFET';
import OpeningHours from './components/Privacy/OpeningHours';
import MittagsBuffetAdmin from './components/Admin/MittagsBuffetAdmin';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
        <Header />  
        <div style={{marginBottom:'100px'}}>
          <Routes>
          <Route path='/' element={<IndexElement />}></Route>
            <Route path='/reservierung' element={<Reservierung />}></Route>
            <Route path='/uberuns' element={<Uberuns />}></Route> 
            <Route path='/privacy' element={<Privacy />}></Route> 
            <Route path='/adminorder' element={<AdminOrderView />}></Route> 
            <Route path='/impressum' element={<Impressum />}></Route> 
            <Route path='/contact' element={<Contact />}></Route>
            <Route path='/brunch' element={<Brunch />}></Route>
            <Route path='/catering' element={<Catering />}></Route>
            <Route path='/Kulinarische' element={<Kulinarische />}></Route>
            <Route path='/MittagsBuffetAdmin' element={<MittagsBuffetAdmin />}></Route>
             <Route path='/OpeningTime' element={<OpeningHours />}></Route>
            <Route path='/Mittagsmenu' element={<AKTUELLES showTitle="true"/>}></Route>
            <Route path='/Silvesterbuffet' element={<SILVESTERBUFFET />}></Route>
            <Route path='/Weihnachtsmenu' element={<Weihnachtsmenukarte />}></Route>
          </Routes>  
        </div>  
        <Footer />  
    </div>
    </BrowserRouter>
  );
}

export default App;
